.header {
    position: absolute;
    @include size(100%,550px);
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        width: calc((100% - ($large - $gl-gutter))/2 + 520px);
        height: 100%;
        border-radius: 0 $border-radius $border-radius 0;
        background-color: $color-third;
        left: 0;
        z-index: -1;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -100%;
        background-color: $color-light;
        border-radius: $border-radius 0 0 $border-radius;
        height: 100%;
        width: calc((100% - (100% - ($large - $gl-gutter))/2 - 335px)); //335 = 270px (largeur menu) + 65px padding right jusqu'au submenu
        transition: .6s;
        z-index: 2;
    }

    > .container {
        height: 100%;
    }

    &__content {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    } 

    &.js-active-submenu {
        &:after {
            right: 0;
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content, 60px);
    transition: color $duration $timing;

    &:hover, &:focus {
        color: $color-second;
    }

    &__title {
        margin: 0;
        font-size: 0;
        line-height: 0;
    }

    svg {
        path[fill="#110035"]{
            fill: $color-main;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    column-gap: 10px;

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        margin: 0;
        padding: 0;
        background: var(--color-bg-tools);
        border-radius: $border-radius--round;
        border: 0;
        cursor: pointer;
        position: relative;

        svg {
            @include size(40px);
            fill: $color-main;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-dark-white;

            svg {
                fill: $color-white-dark;
            }
        }

        &--search {
            fill: none;
            stroke: $color-main;

            path {
                fill: none;
                stroke: $color-main;
                transition: $duration;
            }

            &:hover, &:focus {
                stroke: $color-white-dark;
    
                path {
                    stroke: $color-white-dark;
                }
            }

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }

    // Google translate en attente
    &__translate {
        position: relative;

        &__wrapper {
            /* @include absolute($left: 50%, $top: 65px); */
            @include absolute($left: -50px, $top: 53px);
            display: none;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 10px 30px 10px 20px ;
            background-color: $color-main;
            border-radius: $border-radius;
            /* transform: translateX(-50%); */
            z-index: 3;
            //filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                @include absolute($top: -6px, $left: 70px);
                /* @include absolute($top: -6px, $left: 50%); */
                display: block;
                @include size(30px);
                background-color: $color-main;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
                color: $color-second;
                display: flex;
                align-items: center;
                column-gap: 10px;
                
                &:after {
                    content: '';
                    display: inline-block;
                    gap: 10px;
                    background-color: $color-second;
                    @include size(5px,1px);
                }

                &:nth-child(6) {
                    &:after {
                        display: none;
                    }
                }
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            @include absolute( $left: 100%);
            @include flex($alignItems: center, $justifyContent: center);
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border-color: $color-second;
            border-radius: $border-radius--round;
            transition: background-color $duration $timing;

            svg {
                @include size(26px);
                fill: $color-white-dark;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: $color-white-dark;
                border-color: $color-white-dark;

                svg {
                    fill: $color-dark-white;
                }
            }
        }
    }

}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    &__container {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background-color: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: padding-left $duration $timing;

                        &:hover, &:focus {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

// For OLD access bar
// #uci_link {
//     display: none;
// }

//======================================================================================================
// Header Fixed
//======================================================================================================
@keyframes headerFixed {
    from {
        top: -200px;
        opacity: 0;
        position: absolute;
    }
    to {
        top: 0;
        opacity: 1;
        position: fixed;
    }
}
@keyframes headerFixedAdmin {
    from {
        top: -200px;
        opacity: 0;
    }
    to {
        top: 32px;
        opacity: 1;
    }
}

body:not(.home) {
    .header {
        z-index: 1;
    }
}

.header {
    &--fixed {
        position: fixed;
        z-index: 5!important;
        top: 0;
        height: 80px;
        animation: 1s headerFixed;
        opacity: 1;
        overflow: initial;
        transition: .5s;

        &:before {
            height: 80px;
            border-radius: 0px 0px $border-radius $border-radius;
            /* box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.08); */
            @include size(100%,80px);
        }
        .header {
            &__content {
                padding: 0;
                flex-direction: row;
                align-items: center;
            }
        }

        .identity {
            svg {
                @include size(165px,40px);
            }
        }

        .tools {
            &__item {
                z-index: 1;
            }
        }
    }
}

.admin-bar {
    .header--fixed {
        top: 32px;
        animation: 1s headerFixedAdmin;
        opacity: 1;
    }
} 


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {    
        &:before {
            width: calc((100% - ($medium - $gl-gutter-md))/2 + 415px);
        }
        &:after {
            width: calc((100% - (100% - ($medium - $gl-gutter-md))/2 - 310px));
        }

        &--fixed {
            &:before {
                width: 100%;
            }
        }
    }
    
}


// 960
@media screen and (max-width: $medium) {

    .header {    
        position: relative;
        @include size(100%, auto);
        overflow: initial;

        &:before, &:after {
            display: none;
        }

        &__content {
            position: relative;
            @include size(300px, auto);
            margin: auto;
            padding: 30px 0 40px;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 30px;
            justify-content: center;
            column-gap: 10px;

            &:before {
                content: '';
                @include size(100%,55px);
                border-radius: $border-radius $border-radius;
                background-color: $color-third;
                bottom: 30px;
                position: absolute;
                z-index: -1;
            }
        }
    }

    .identity {
        width: 100%;
        
        svg {
            @include size(210px,50px);
        }
    }

    .tools {
        &__item {
            @include size(35px);

            svg {
                @include size(35px);
            }
        }
        &__translate {
            &__wrapper {
                flex-direction: column;
                padding: 10px 20px 25px 20px;
                left: 50%;
                transform: translateX(-50%);

                &:before {
                    left: 50%;
                }

                a.glink:after {
                    display: none;
                }
            }
            &__close {
                bottom: -10px;
                left: 50%;
            }
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

}
