.post_navigation {
    // If buttons prev and next is here
    // @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-evenly);
    // gap: 30px;
    // @include flex($justifyContent: center);
    padding: 10px 0 40px;

    .navigation_link {
        @include flex($alignItems: center);
        text-transform: uppercase;
        font-size: $font-size--text-small;
        font-weight: $font-weight-semibold;
        color: $color-main;
        transition: $duration $timing;
        height: 50px;
        padding: 0 30px;
        background-color: $color-light;
        border-radius: 50px;

        svg {
            @include size(26px);
            fill: $color-main;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            color: $color-white;
            background-color: $color-one;

            svg {
                fill: $color-white;
            }
        }
    }
}

// // for all buttons navigations
// .navigation_link {
//     column-gap: 5px;
// }


// If buttons prev and next is here
// 640
// @media screen and (max-width: $small) {

//     .post_navigation {
//         .return {
//             @include flex($justifyContent: center);
//             order: 1;
//             flex: 1 1 100%;
//         }
//     }

// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
        //fix alignement center without prev or next
        min-width: 182px;

        .navigation_link {
            padding: 0;
            color: $color-main;
            width: fit-content;

            // Recup style ~ ligne 9
            a {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                height: 50px;
                padding: 0 30px;
                transition: color 0.25s;
            }
        }
    }

    .prev {
        .navigation_link {
            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-main;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        display: flex;
        justify-content: flex-end;

        .navigation_link {
            a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-main;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $color-white;
                background-color: $color-one;

                svg {
                    fill: $color-white;
                }

                a {
                    color: $color-white;
                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

/// Si les navigation link ont un style de bouton ///
// 960
@media screen and (max-width: $medium) {

    .post_navigation {

        &--container {
            gap: 20px 0;
        }

        .return {
            .navigation_link {
                padding: 0 20px;
            }
        }

        .prev, .next {
            // A changer en fonction de la taille du bouton "suivant"
            min-width: 135px;

            .navigation_link a {
                padding: 15px 20px;
            }
        }
    }
}
/// END - Si les navigation link ont un style de bouton ///

// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;
        }

        .return {
            @include flex($justifyContent: center);
            order: 1;
            flex: 1 1 100%;

            /// Si les navigation link ont un style de bouton ///           
            width: 100%;

            a {
                width: 100%;
                justify-content: center;
            }
            /// END - Si les navigation link ont un style de bouton ///
        }

    }
}

// 360
@media screen and (max-width: $x-small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;
            width: 100%;

            .navigation_link {
                width: 100%;
                
                a {
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }
}