//======================================================================================================
// Listing
//======================================================================================================
.listing {
    &__line {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px;
        box-shadow: $shadow--line; 

        > svg {
            width: 60px;
            height: 60px;
            margin: 0 10px 0 0;
            fill: $color-svg--events;  
        }

        div,
        p {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 5px;
        }

        div {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--accessibility {
            align-items: center;
            margin: 0 20px 0 0;
            padding: 0;
            box-shadow: none;

            &:first-of-type {
                width: 100%;
                margin: 0 0 20px;
            } 
        }

        &--files {
            div,
            p {
                display: block; 
            }
        }
    }

    &--files {
        max-width: 60%;
    }

    &__title {
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        color: $color--card;
        text-transform: uppercase;
        margin-bottom: 5px;

        &--accessibility {
            color: $color--card;
        }

        &--files {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            @include font-size(22);
            line-height: $line-height--heading;
            color: $color--card;
            text-transform: initial;
            margin: 20px 0;
        }
    }
    
    &--accessibility {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 25px 10px;
    }
    
    &--files {

        .files-infos {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
                position: relative;
                display: flex;
                align-items: center;
                height: 30px;
                margin-bottom: 5px;
                color: $color--card;
                
                &:after {
                    content: '';
                    display: inline-block;
                    vertical-align: bottom;
                    margin: auto 8px;
                    width: 1px;
                    height: 70%;
                    background-color: $color-bg--neutral;

                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }

            &__type {
                text-transform: uppercase;
            }

            &__taxonomy {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                padding: $taxo-padding;
                background-color: $taxo-bg;
                border: $taxo-border-width $taxo-border-style $taxo-border-color;
                border-radius: $taxo-border-radius;
                font-family: $taxo-font-family;
                font-size: $taxo-font-size;
                font-weight: $taxo-font-weight;
                font-style: italic;
                line-height: $taxo-line-height;
                text-transform: $taxo-text-transform;
                color: $taxo-color;
                column-gap: 10px;

                &:after {
                    /* display: none !important; */
                    margin: 0!important;
                } 

                &:before {
                    content: '';
                    @include size(30px,1px);
                    background-color: $color-main;
                    display: inline-block;
                }
            }
        }
    }

    &-contact {
        li {
            display: flex;
            flex-direction: column;

            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        &__name {
            font-weight: $font-weight-bold;
        }

        &__mail {
            display: flex;
            text-decoration: underline;
            word-break: break-all;
            
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // .card__container--document {
    //     .document-details {
    //         flex-wrap: wrap;
    //         margin-bottom: 0;

    //         .listing--files {
    //             max-width: inherit;
    //             flex: 100%;
    //         }

    //         .content-right {
    //             flex: inherit;
    //             margin-top: 20px;

    //             .link-document {
    //                 &:first-child {
    //                     margin: 0;
    //                 }
    //             }
    //         }
    //     }
    // }

}


// 960
@media screen and (max-width: $medium) {

    .listing--files .files-infos__taxonomy {
        height: auto !important;
    }

}


// 640
@media screen and (max-width: $small) {

    .listing--files {
        .listing__title--files {
            @include font-size(19);
            margin: 0 0 15px 0;
        }

        .listing--accessibility,
        .files-infos {
            flex-wrap: wrap;
        }

        .files-infos {
            li:not(.files-infos__taxonomy) {
                font-size: $font-size--text-small;
            }
        }

        .files-infos__taxonomy {
            margin-bottom: 5px;
        }
    }

    // .card__container--document {
    //     .document-details {
    //         .content-right {
    //             text-align: left;

    //             .link-document {
    //                 margin: 0 20px 0 0;
                    
    //                 &:first-child {
    //                     margin-right: 20px;
    //                 }
    //             }
    //         }
    //     }
    // }

}
