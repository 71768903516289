.footer {
    .button {
        margin: 0;
    }

    .container--footer {
        position: relative;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        column-gap: 50px;
        padding-bottom: 80px;
    }

    &__infos {
        padding: 85px 0 40px;
        display: flex;
        width: 100%;
        column-gap: 50px;
    }

    &__title {
        font-size: $font-size--text-medium;
        line-height: toRem(25);
        margin: 0 0 10px;
        font-weight: $font-weight-semibold;
    }

    &__text {
        line-height: toRem(25);
    }
    
    &__address {
        width: 250px;
    }

    &__phone {
        &__numbers {
            display: inline-flex;
            align-items: center;
            column-gap: 8px;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color .25s ease-in-out;

            &:hover, &:focus-within {
                color: $color-date--single;
                text-decoration-color: $color-date--single;
            }
        }
    }

    &__contact {
        width: 250px;

        &__btn {
            width: 200px!important;
            padding: 0; 
        }
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        gap: 0px 40px;
        max-width: 600px;

        &__item {
            display: flex;
            align-items: center;
            column-gap: 40px;

            &:after {
                content: '';
                display: inline-block;
                @include size(18px,1px);
                background-color: $color-text;
            }

            &:nth-child(3),
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

        &__link {
            font-size: $font-size--text-small;
            line-height: toRem(20);
            opacity: 1;
            transition: opacity .25s ease-in;

            &.active {
                opacity: .5;
            }

            &:hover, &:focus-within {
                opacity:1;
            }

        }
    }
}

.network {
    background-color: $color-dark;
    border-radius: $border-radius;
    @include size(400px,225px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    padding: 0 25px;
    position: absolute;
    top: 160px;
    right: 75px;

    &__title {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        font-size: $font-size--text-medium;
        line-height: toRem(25);

        &:after {
            content: '';
            display: block;
            @include size(30px,1px);
            background-color: $color-white;
        }
    }

    &__text {
        color: $color-white;

        span {
            font-weight: $font-weight-bold;
        }
    }

    &__buttons {
        display: flex;
        column-gap: 10px;
    }

    &__btn {
        background: rgba(255, 255, 255, 0.10);
        border: none;
        padding: 0 20px;
        column-gap: 10px;
        min-height: 0;
        height: 40px;
        color: $color-white;

        svg {
            fill: $color-white;
            @include size(20px);
        }

        &:hover, &:focus-within {
            background-color: $color-white;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {    
        &__content {
            column-gap: 15px;
        }
    
        &__infos {
            padding: 100px 0 60px;
            column-gap: 15px;
        }

        &__title {
            font-size: $font-size--text;
            line-height: toRem(20);
        }

        &__text {
            font-size: $font-size--text-small;
            line-height: toRem(20);
        }

        &__phone {
            font-size: $font-size--text-small;
            line-height: toRem(20);
        }
    }
    
    .network {
        @include size(400px,215px);
        right: 60px;
    
        &__text {
            font-size: $font-size--text-small;
        }
    
        &__buttons {
            display: flex;
            column-gap: 10px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        .container--footer {
            max-width: 100%;
            padding: 0;
        }

        &__content {
            margin: 0 auto;
            max-width: 90%;
            padding: 0 10px 70px 10px;
        }

        &__infos {
            padding: 80px 0 30px;
            column-gap: 60px;
        }

        &__address  {
            width: 300px;
        }

        &__contact {
            width: 300px;

            &__btn {
                width: 100%!important;
            }
        }

        &__menu {
            margin-top: 30px;
            column-gap: 20px;
            row-gap: 20px;
            max-width: 800px;

            &__item {
                column-gap: 20px;

                &:nth-child(3){
                    &:after {
                        display: inline-block
                    }
                }
            }
        }
    }

    .network {
        @include size(100%,auto);
        position: relative;
        margin-top: -40px;
        top: auto;
        right: auto;
        padding: 20px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        &__title {
            width: 100%;
        }

        &__text {
            max-width: 360px;
        }

        &__btn {
            height: 40px;
            width: 40px!important;
            padding: 0!important;
            border-radius: $border-radius--round;

            span {
                display: none;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos {
            column-gap: initial;
            padding: 60px 0 30px;
            flex-wrap: wrap;
            row-gap: 30px;
        }

        &__address {
            width: 240px;
            flex: none;
        }

        &__hours {
            width: 300px;
            flex: none;
        }
    }

    .network {
        margin-top: -30px;
    }
}