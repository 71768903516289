html[data-theme="dark"] {
    /* btn dark/light */
    .tools-theme {
		&__light {
			opacity: 0;
		}

		&__dark {
			opacity: 1;
		}
    }

	h4 {
		color: $color-text;
	}

    .network {
        background-color: $color-one;
    }

  /* MENU */
  .nav-main .submenu__link {
    &:hover, &:focus {
      color: #E4E1ED;
    }

    &:before {
      color: $color-white;
    }
  }
  .nav-main .submenu__title:before {
    background-image: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_nav_gauche.svg);
  }
  

	/* SEARCH */
	.search-popup {
		background-color: $color-third;
	}

	.search-container #searchInput {
		background-color: $color-dark;
		border-color: $color-dark;
		color: $color-white;

		&::placeholder {
			color: $color-white;
		}
	}

	.searchResult {
		background-color: $color-dark;
		border-color: $color-white;

		li {
			background-color: $color-dark;
			border-color: $color-white;

			.resultCPT {
				color: $color-white;
			}

			&:not(.no-results) {
				a {
					&:hover, &:focus {
						color: $color-second;

						+ .resultCPT {
							color: $color-second;
						}

						&:after {
							color: $color-second;
						}
					}
				}
			}	
		}
	}

	/* HOME */
    .home {
        .posts {
          &__navigation {
            background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/forme_fleche_dark.svg) no-repeat center;
          }
          &__item__container-img {
            background-image: url(/wp-content/themes/laille/assets/src/images/icon-post-blanc.png);
          }
        }
    
        .events {
          &__navigation {
            background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/forme_agenda_dark.svg) no-repeat center;
          }
          &__item {
            background-color: $color-one;
            &--0 {
              background-color: $color-bg--transparent;

              .events__item__container-img {
                background-image: url(/wp-content/themes/laille/assets/src/images/icon-event-blanc.png);
              }
            }
            &:after {
              background-color: $color-dark;
            }
            &:hover, &:focus-within {
              &:after {
                background-color: $color-three;
              }
            }
          }

          &__template {
            &__btn {
                &--prop {
                    color: $color-white;
                    &:hover, &:focus-within {
                      color: $color-white-dark;
                      background-color: $color-dark-white;
                      border-color: $color-dark-white;

                  }
                }
            }
          }
        }
    }

    /* Pages intérieures */
    .ui-datepicker.ui-widget-content .ui-datepicker-title {
      background-color: $color-third;
    }

    #filters-maps .filter .filter-container {
      border-color: $color-white
    }
    #filters-maps .filter__select-wrapper.selected .filter-container {
      border-color: $color-one
    }

    .post_navigation .navigation_link{
      &:hover, &:focus {
        background-color: $color-white;
        color: $color-dark;

        svg {
          fill: $color-dark;
        }
      }
    }

    .post_navigation {
      & .prev, & .next {
        .navigation_link a {
          &:hover, &:focus, &:focus-within {
            color: $color-dark;
            
            &:before, &:after {
              background-color: $color-dark;
            }
          }
        }
      }
    }

    .card__publication-date,
    .card__location {
      color:$color-light-same;

      p {
        color:$color-light-same;
      }

      svg {
        fill:$color-light-same;
      }
    }

    #content form select option {
      color: $color-dark;
    }

    .image-wrapper {
      &.consultations {
          background-image: url(/wp-content/themes/laille/assets/src/images/icon-consultations-blanc.png);

      }
      &.contacts,
      &.contacts_category {
        background-image: url(/wp-content/themes/laille/assets/src/images/icon-contacts-blanc.png);
      }

      &.post,
      &.category {
        background-image: url(/wp-content/themes/laille/assets/src/images/icon-post-blanc.png);
      }

      &.page,
      &.transitions {
        background-image: url(/wp-content/themes/laille/assets/src/images/icon-page-blanc.png);
      }

      &.documents,
      &.documents_category {
        background-image: url(/wp-content/themes/laille/assets/src/images/icon-documents-blanc.png);
      }
      &.events,
      &.events_category {
        background-image: url(/wp-content/themes/laille/assets/src/images/icon-event-blanc.png);
      }
  }
}

/* btn dark/light */
.tools-theme {
    @include size(40px);
    cursor: pointer;
    position: relative;

    &:hover, &:focus {
        background-color: $color-dark-white;

        svg {
          path {
            fill: $color-white-dark;
          }
        }
    }

    input {
      @include size(40px);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }

    &__light,
    &__dark {
      @include size(40px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: $duration;
      svg {
        path {
          transition: $duration;
        }
      }
    }
    &__light {
      opacity: 1;
    }

    &__dark {
      opacity: 0;
    }
}

// Maps //
.leaflet-control-geosearch form {
  color: #110035 !important;

  input {
    color: #110035 !important;
  }
} 

// 960
@media screen and (max-width: $medium) {
  .tools-theme {
    @include size(35px);

    input {
      @include size(35px);
    }

    &__light,
    &__dark {
      @include size(35px);
    }
  }
}