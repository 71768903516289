.breadcrumb {
    ul {
        display: block;
        padding: 10px 0;
    }

    li {
        position: relative;
        display: inline;

        a {
            position: relative;
            color: $color-main;
            line-height: toRem(25);
            transition: none;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 1px;
                width: 0;
                background-color: $color-main;
                transition: $duration;
            }

            &:hover, &:focus {
                color: $color-main;

                &:before {
                    width: 100%;
                }
            }
        }


        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-main;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
