.home {
    &__title {
        display: flex;
        flex-direction: column;
        font-weight: $font-weight-extrabold;
        font-size: toRem(55);
        line-height: toRem(65);
        margin: 0;

        &:before {
            display: none;
        }
    }

    &__subtitle {
        font-weight: $font-weight-light;
        font-size: $font-size--text-medium;
        line-height: toRem(25);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        column-gap: 15px;

        &:before {
            content: '';
            display: inline-block;
            @include size(75px,1px);
            background-color: $color-text;
        }
    }

    &__intro {
        line-height: toRem(25);
    }

    .button {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    /* Swiper */
    &__navigation {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        padding-left: 10px;
    
        &.disable {
            display: none
        }
    }

    .swiper-button {
        padding: 0;
        margin: 0;
        position: relative;
        top: 10px;
        background-color: $color-bg--transparent;
        border: none;
        @include size(12px);
        transition: $duration;

        &:hover, &:focus-within {

            svg {
                fill: $color-three;
            }
        }

        svg {
            position: relative;
            fill: $color-text;
            flex: none;
            @include size(12px);
            transition: $duration;
        }

        &:after {
            display: none;
        }

        &-next {
            right: auto;
        }

        &-prev {
            left: auto;
            transform: rotate(180deg);
        }

    }


    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access{
        position: relative;
        margin-left: calc(50% - 20px);
        overflow: hidden;

        &__container {
            overflow: hidden;
        }

        &__navigation {
            top: -20px;
            bottom: 0;
            margin: auto;
            left: 0;
            align-items: center;
            flex-direction: column;
            row-gap: 10px;
        }

        .swiper-button {
            @include size(40px);
            border-radius: $border-radius--round;
            background-color: $color-four;

            svg {
                fill: $color-white;
            }

            &:hover, &:focus-within {
                background-color: $color-three;

                svg {
                    fill: $color-dark;
                }
            }
        }

        &__list {
            position: relative;
            padding-left: 85px;
        }
        
        .swiper-container {
            height: 550px;
            width: 540px; //520px + margin-right des slides 
            margin: 0;
            overflow: initial;
        }

        .swiper-slide {
            width: 250px!important;
            margin-right: 20px;

            &:nth-child(odd){
                margin-bottom: 20px;
            }

            &:nth-of-type(1),
            &:nth-of-type(7) {
                height: 265px!important; 
            }

            &:nth-of-type(2),
            &:nth-of-type(8) {
                height: 265px!important; 
            }

            &:nth-of-type(3),
            &:nth-of-type(9) {
                height: 330px!important; 
            }

            &:nth-of-type(4),
            &:nth-of-type(10) {
                height: 200px!important; 
            }

            &:nth-of-type(5) {
                height: 300px!important; 
            }

            &:nth-of-type(6) {
                height: 230px!important; 
            }
        }

        &__item {
            display: block;
            position: relative;
            @include size(100%);
            border-radius: $border-radius;
            overflow: hidden;

            &:hover, &:focus-within {
                .access {
                    &__content {
                        /* background: linear-gradient(224deg,transparent,rgba(0,0,0,.8) 84.04%),linear-gradient(0deg,rgba(0,0,0,.1),rgba(0,0,0,.1)); */
                        &:after {
                            opacity: 1;
                        }
                    }

                    &__title {
                        margin-bottom: 10px;
                        &:after {
                            width: 30px;
                        }
                    }
                }
            }
        }

        &__image {
            @include size(100%);

            img {
                @include size(100%);
                object-fit: cover;
            }
        }

        &__content {
            @include size(100%);
            position: absolute;
            top: 0;
            left: 0;
            padding: 20px 25px;
            box-sizing: border-box;
            display: flex;
            align-items: flex-end;
            background: linear-gradient(224deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 84.04%), linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%);
            transition: $duration;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                @include size(100%);
                background-color: rgba(0,0,0,.2);
                opacity: 0;
                transition: .3s ease-in;
            }
        }

        &__title {
            display: block;
            color: $color-white;
            text-shadow: $shadow--text;
            font-size: $font-size--text-medium;
            line-height: toRem(25);
            font-weight: $font-weight-semibold;
            margin-bottom: 0;
            z-index: 1;
            transition: $duration;

            &:after {
                content: '';
                display: block;
                height: 1px;
                width: 0;
                margin-top: 5px;
                background-color: $color-white;
                transition: .4s ease-in;
            }
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        padding-top: 100px;

        > .container {
            display: flex;
            justify-content: space-between
        }

        &__header {
            display: flex;
            flex-direction: column;
            max-width: 270px;
        }

        &__intro {
            margin: 40px 0 45px;
        }

        &__list {
            position: relative;
            @include size(750px,340px);

            &:before {
                content: '';
                position: absolute;
                bottom: 5px;
                left: 5px;
                @include size(350px,10px);
                border-radius: $border-radius;
                background-color: $color-light;
            }
        }

        &__navigation {
            height: 42px;
            right: 0;
            top: 40px;
            width: 84px;
            background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/forme_fleche.svg) no-repeat center;

            &:before {
                content: '';
                position: absolute;
                background-color: $color-second;
                top: -10px;
                right: 0;
                @include size (84px,10px);
            }
        }

        .swiper-slide {
            width: 360px!important;
            height: 300px!important;
            margin-right: 30px;
            border-radius: $border-radius;
            overflow: hidden;
            transition: margin .25s ease-in-out!important;
            margin-top: 40px;

            &-active {
                margin-top: 0px!important;
                transition: margin .75s ease-in-out!important;
            }

            &:last-child {
                margin-right: 0!important;
            }
        }
    
        &__item {
            position: relative;
            @include size(100%);
            display: block;

            &:hover, &:focus-within {
                .posts {
                    &__item {
                        &__taxo {
                            &:before {
                                width: 40px; 
                            }
                        }
                    }
                    &__content {
                        background: linear-gradient(224deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.7) 84.04%);   
                    }
                }
            }

            &__container-img {
                @include size(100%);
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) center no-repeat $color-bg--image;

                img {
                    @include size(100%);
                    object-fit: cover;
                }
            }

            &__content {
                @include size(100%);
                padding: 20px;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                row-gap: 10px;
                background: linear-gradient(224deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 84.04%);     
                z-index: 1;
                box-sizing: border-box;
                transition: $duration;
            }

            &__taxo {
                color: $color-white;
                text-shadow: $shadow--text;
                font-style: italic;
                line-height: toRem(25);
                display: flex;
                align-items: center;
                column-gap: 10px;
                transition: $duration;

                &:before {
                    content: '';
                    display: inline-block;
                    @include size(30px,1px);
                    background-color: $color-white;
                    transition: $duration;
                }
            }

            &__title {
                color: $color-white;
                font-size: $font-size--text-medium;
                line-height: toRem(25);
                font-weight: $font-weight-semibold;
                text-shadow: $shadow--text;
                margin: 0;
            }
        }    
    }

    //======================================================================================================
    // Info Travaux
    //======================================================================================================
    .newsthread {
        padding-top: 25px;

        &__container {
            position: relative;
            z-index: 1;
            background-color: $color-two;
            max-width: 780px;
            @include size(100%,75px);
            display: flex;
            align-items: center;
            column-gap: 20px;
            border-radius: $border-radius;
            transform: translateY(50%);
            padding: 5px 25px;
        }

        &__title {
            font-size: $font-size--text-medium;
            line-height: toRem(25);
            font-weight: $font-weight-semibold;
            color: $color-dark;
            text-transform: uppercase;
            flex: none;
        }

        &__link {
            display: flex;
            align-items: center;
            column-gap: 20px;
            color: $color-dark;
            line-height: toRem(25);
            padding-right: 10px;
            height: 100%;

            &:before {
                content: '';
                display: inline-block;
                @include size(30px,1px);
                background-color: $color-dark;
                transition: $duration;
            }

            &:hover, &:focus-within {
                padding-right: 0;

                &:before {
                    width: 40px;
                }
            }
        }
    }

    //======================================================================================================
    // Evenements
    //======================================================================================================
    .events {
        padding-top: 100px;
        
        > .container {
            display: flex;
            justify-content: space-between
        }

        &__header {
            display: flex;
            flex-direction: column;
            max-width: 270px;
        }

        &__intro {
            margin: 50px 0 75px;
        }

        &__template {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            &__btn {
                &--prop {
                    background-color: $color-light;
                    border-color: $color-light;

                    &:hover, &:focus-within {
                        color: $color-white-dark;
                        background-color: $color-dark-white;
                        border-color: $color-dark-white;

                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 750px;
        }

        &__list {
            position: relative;
            padding-bottom: 40px;

            &:before {
                content: '';
                position: absolute;
                left: -50px;
                bottom: 0;
                height: 195px;
                width: calc(100% + 50px + ((100vw - ($large - $gl-gutter))/2)); // width 100% du container + 50px du padding gauche + l'espace à droite du container-écran
                border-radius: 10px 0 0 10px;
                background-color: $color-light;
            }
        }

        &__navigation {
            height: 42px;
            right: 0;
            top: 0;
            width: 74px;
            background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/forme_agenda.svg) no-repeat center;
        }

        .swiper-slide {
            width: 230px!important;
            height: 260px!important;
            margin-right: 30px;
            transition: margin .25s ease-in-out!important;

            &:last-child {
                margin-right: 0!important;
            }
        }
        
    
        &__item {
            position: relative;
            display: flex;
            @include size(100%,235px);
            border-radius: $border-radius;
            background-color: $color-dark;

            &:hover, &:focus-within {
                .events {
                    &__item {
                        &__taxo:before {
                            width: 40px
                        }
                    }
                }
            }

            &__infos {
                @include size(100%);
                padding: 35px 20px 25px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
            }

            &__taxo {
                color: $color-white;
                font-style: italic;
                line-height: toRem(25);
                display: flex;
                align-items: center;
                column-gap: 10px;
                max-height: 50px;

                &:before {
                    content: '';
                    display: inline-block;
                    @include size(30px,1px);
                    background-color: $color-white;
                    flex: none;
                    transition: $duration;
                }
            }

            &__date {
                margin: 20px 0 10px;
                color: $color-white;
                text-transform: uppercase;
                font-size: toRem(18);
                line-height: toRem(25);
                font-weight: $font-weight-light;
            }

            &__title {
                color: $color-white;
                font-size: $font-size--text-medium;
                line-height: toRem(25);
                font-weight: $font-weight-semibold;
                margin: 0
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                @include size(50px);
                bottom: -25px;
                right: 20px;
                border-radius: $border-radius--round;
                transition: $duration;
            }

            &--sport {
                &:after {
                    background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_sport.svg) no-repeat center $color-one;
                }

                &:hover, &:focus-within {
                    &:after {
                        background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_sport_hover.svg) no-repeat center $color-three;
                    }
                }
            }

            &--associations {
                &:after {
                    background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_associations.svg) no-repeat center $color-one;
                }

                &:hover, &:focus-within {
                    &:after {
                        background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_associations_hover.svg) no-repeat center $color-three;
                    }
                }
            }

            &--culture {
                &:after {
                    background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_culture.svg) no-repeat center $color-one;
                }

                &:hover, &:focus-within {
                    &:after {
                        background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_culture_hover.svg) no-repeat center $color-three;
                    }
                }
            }

            &--environnement {
                &:after {
                    background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_environnement.svg) no-repeat center $color-one;
                }

                &:hover, &:focus-within {
                    &:after {
                        background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_environnement_hover.svg) no-repeat center $color-three;
                    }
                }
            }

            &--municipale {
                &:after {
                    background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_vie_municipale.svg) no-repeat center $color-one;
                }

                &:hover, &:focus-within {
                    &:after {
                        background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_vie_municipale_hover.svg) no-repeat center $color-three;
                    }
                }
            }

            &--divers {
                &:after {
                    background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_divers.svg) no-repeat center $color-one;
                }

                &:hover, &:focus-within {
                    &:after {
                        background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_cate_divers_hover.svg) no-repeat center $color-three;
                    }
                }
            }

            &--0 {
                @include size(100%,200px);
                display: flex;
                align-items: center;
                column-gap: 30px;
                border-radius: 0;
                background-color: $color-bg--transparent;

                &:after {
                    top: 20px;
                }

                .events {
                    &__item {
                        &__container-img {
                            @include size(400px,100%);
                            background: url(/wp-content/themes/noyau/assets/images/icon-events.png) center no-repeat $color-bg--image;
                            border-radius: $border-radius;
                            overflow: hidden;
                            flex: none;
                            position: relative;
            
                            img {
                                @include size(100%);
                                object-fit: cover;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                @include size(100%);
                                background: rgba(128,128,128,.3);
                            }
                        }

                        &__infos {
                            max-width: 280px;
                            @include size(100%);
                            padding: 0;
                            justify-content: center;
                        }

                        &__title, &__taxo, &__date {
                            color: $color-text;
                        }

                        &__taxo {
                            &:before {
                                background-color: $color-text;
                            }
                        }
                    }
                }
            }
        }
    }

    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque {
        padding: 80px 0 70px;
        
        > .container {
            display: flex;
            justify-content: space-between
        }

        &__header {
            display: flex;
            flex-direction: column;
            max-width: 335px;
        }

        &__intro {
            margin: 40px 0;
        }

        &__template {
            display: flex;
            max-width: 270px;
        }

        &__content {
            display: flex;
            align-items: flex-end;
            column-gap: 30px;
        }

        &__item {
            position: relative;

            &__image {
                border-radius: $border-radius;
                overflow: hidden;
                flex: none;

                img {
                    @include size(100%);
                    object-fit: cover;
                }

                &--no-image {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: $color-bg--image;
              
                  svg {
                    width: 40%;
                  }
                }
            }

            &__title {
                margin: 10px 0 0;
                font-size: $font-size--text-medium;
                line-height: toRem(25);
                font-weight: $font-weight-semibold;
            }

            &__date {
                text-transform: uppercase;
                font-size: toRem(15);
                line-height: toRem(20);
                font-weight: $font-weight-light;
            }

            &__content {
                width: 100%;
            }

            &__buttons {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
            }

            &__btn {
                margin: 0;

                svg {
                    @include size(40px);
                    margin: 0;
                }
            }

            &--1 {
                .kiosque {
                    &__item {
                        &__image {
                            @include size(300px);
                        }

                        &__content {
                            position: absolute;
                            top: 0;
                            left: 330px;
                            max-width: 205px;
                        }

                        &__buttons {
                            margin-top: -15px;
                            margin-right: 20px;
                        }

                        &__btn {
                            svg {
                                background-color: $color-four;
                                fill: $color-white;
                            }

                            &:hover, &:focus {
                                svg {
                                    background-color: $color-dark-white;
                                    fill: $color-white-dark;
                                }
                            }
                        }
                    }
                }
            }

            &--2 {
                display: flex;
                align-items: flex-end;
                column-gap: 20px;

                .kiosque {
                    &__item {
                        &__image {
                            @include size(205px,220px);
                        }

                        &__content {
                            max-width: 130px;
                        }

                        &__buttons {
                            flex-direction: column;
                        }

                        &__btn {
                            svg {
                                background-color: $color-two;
                                fill: $color-dark;
                            }

                            &:hover, &:focus {
                                svg {
                                    background-color: $color-dark-white;
                                    fill: $color-white-dark;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

//======================================================================================================
// Administration images background home + footer
//======================================================================================================
.admin-image {
    overflow: hidden;
    @include size(100%,300px);
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background:rgba(211,211,211,.1);
        @include size(100%);
    }
    
    &--2 {
        border-radius: $border-radius;
    }

    img {
        @include size(100%);
        object-fit: cover;
    }

    &--1 {
        margin-top: 100px;
    }
}

  
.newsthread {
    & + .admin-image--1 {
        margin-top: 0;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        &__intro {
            font-size: $font-size--text-small;
            line-height: toRem(20);
        }

        .access{
            margin-left: calc(50% - 45px);

            &__navigation {
                top: -20px;
            }

            &__list {
                padding-left: 75px;
            }

            .swiper-container {
                width: 460px;// 440px + 20px margin-right des slides
            }

            .swiper-slide{
                width: 210px!important;
            }

            &__title {
                font-size: toRem(18);
                line-height: toRem(22);
            }
        }

        .posts {    
            &__header {
                max-width: 260px;
            }
    
            &__list {
                @include size(620px,325px);
    
                &:before {
                    @include size(280px,10px);
                }
            }
    
            .swiper-slide {
                width: 300px!important;
                height: 285px!important;
                margin-right: 20px;
            }
        
            &__item {    
                &__taxo {
                    font-size: $font-size--text-small;
                    line-height: toRem(20px);
                }
    
                &__title {
                    font-size: toRem(18);
                    line-height: toRem(22);
                }
            }
        }
    
        .newsthread {            
            &__container {
                max-width: 620px;
            }
    
            &__title {
                font-size: toRem(18);
            }
    
            &__link {
                font-size: $font-size--text-small;
                line-height: toRem(20);
            }
        }

        .events {
            &__intro {
                margin: 50px 0 90px;
            }
            &__content {
                width: 615px;
            }
    
            &__list {    
                &:before {
                    left: -20px;
                    height: 180px;
                    width: calc(100% + 20px + ((100vw - ($medium - $gl-gutter-md))/2)); // width 100% du container + 50px du padding gauche + l'espace à droite du container-écran
                }
            }
    
            .swiper-slide {
                width: 195px!important;
                height: 245px!important;
                margin-right: 15px;
            }
            
        
            &__item {
                @include size(100%,220px);
    
                &__infos {
                    padding: 35px 15px 25px;
                }
    
                &__taxo {
                    font-size: $font-size--text-small;
                    line-height: toRem(20);
    
                    &:before {
                        @include size(30px,1px);
                    }
                }
    
                &__date {
                    font-size: toRem(18);
                    line-height: toRem(25);
                }
    
                &__title {
                    font-size: toRem(18);
                    line-height: toRem(22);
                }
    
                &:after {
                    right: 25px;
                }
    
                &--0 {
                    column-gap: 20px;

                    &:after {
                        right: 20px;
                    }
    
                    .events {
                        &__item {
                            &__container-img {
                                @include size(315px,100%);
                            }
    
                            &__infos {
                                max-width: 250px;
                            }
    
                            &__date {
                                font-size: toRem(16);
                            }
                
                            &__title {
                                font-size: toRem(16);
                                line-height: toRem(20);
                            }
                        }
                    }
                }
            }
        }

        .kiosque {
            padding: 80px 0;    
            &__header {
                max-width: 270px;
            }
    
            &__intro {
                margin: 40px 0 25px;
            }
    
            &__content {
                column-gap: 20px;
            }
    
            &__item {    
                &__title {
                    font-size: toRem(18);
                    line-height: toRem(22);
                }
    
                &--1 {
                    .kiosque {
                        &__item {
                            &__image {
                                @include size(240px,270px);
                            }
    
                            &__content {
                                left: 260px;
                                max-width: 160px;
                            }
                        }
                    }
                }
    
                &--2 {
                    column-gap: 15px;
    
                    .kiosque {
                        &__item {
                            &__image {
                                @include size(170px,200px);
                            }
    
                            &__content {
                                max-width: 170px;
                                position: absolute;
                                left: 185px;
                                bottom: 65px;
                            }
    
                            &__buttons {
                                flex-direction: row;
                            }
                        }
                    }
                }
            }
    
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home {
        &__title {
            margin-bottom: 40px;
        }

        &__intro {
            display: none;
        }

        .access{
            margin-left: calc((100% - (90% - $gl-gutter-sm))/2);
            overflow: initial;

            &__container {
                overflow: initial;
            }

            &__list {
                padding-bottom: 55px;
            }
    
            &__navigation {
                top: auto;
                height: 35px;
                bottom: 0;
                margin: auto;
                left: 0;
                flex-direction: row;
                column-gap: 10px;
            }
    
            .swiper-button {
                @include size(35px);
                top: 0;
            }
    
            &__list {
                padding-left: 0;
            }
            
            .swiper-container {
                height: 620px;
                width: calc(95% + 10px);
            }
    
            .swiper-slide{  
                width: 250px!important;  

                &:nth-of-type(1),
                &:nth-of-type(7) {
                    height: 300px!important; 
                }
    
                &:nth-of-type(2),
                &:nth-of-type(8) {
                    height: 300px!important; 
                }
    
                &:nth-of-type(3),
                &:nth-of-type(9) {
                    height: 375px!important; 
                }
    
                &:nth-of-type(4),
                &:nth-of-type(10) {
                    height: 225px!important; 
                }
    
                &:nth-of-type(5) {
                    height: 340px!important; 
                }
    
                &:nth-of-type(6) {
                    height: 260px!important; 
                }
            }
        }

        .posts {    
            padding-top: 60px;

            > .container {
                flex-direction: column;
                position: relative;
                padding-bottom: 80px;
            }

            &__header {
                max-width: 100%;
            }

            &__template {
                max-width: calc(50% - 20px);
                position: absolute;
                bottom: 0;
                width: 100%;
            }
    
            &__list {
                @include size(100%,auto);
                padding-bottom: 25px;
    
                &:before {
                    width: calc(50% - 20px);
                    bottom: 0;
                }
            }
    
            .swiper-slide {
                width: calc(50% - 10px)!important;
                height: 280px!important;
                margin-top: 0;
            }

            &__navigation {
                top: 0;
            }
        }

        .events {    
            > .container {
                flex-direction: column;
                position: relative;
                padding-bottom: 80px;
            }

            &__header {
                max-width: 100%;
            }

            &__template {
                max-width: 100%;
                flex-direction: row;
                column-gap: 20px;
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            &__content {
                width: 100%;
                row-gap: 20px;
            }
    
            &__list {    
                padding-bottom: 35px;

                &:before {
                    height: 160px;
                    width: calc(100% + 20px + ((100vw - ($small - $gl-gutter-sm))/2)); // width 100% du container + 20px du padding gauche + l'espace à droite du container-écran
                }
            }

            &__infos {
                padding: 30px 20px 25px;
            }
    
            .swiper-slide {
                width: calc(50% - 10px)!important;
                height: 220px!important;
                margin-right: 20px;
            }

            &__item {
                height: 195px;

                &--0 {
                    height: 200px;

                    .events {
                        &__item {
                            &__container-img {
                                width: calc(50% - 10px);
                            }

                            &__infos {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        
        .kiosque {
            > .container {
                flex-direction: column;
                position: relative;
            }

            &__header {
                max-width: 100%;
            }

            &__template {
                width: 300px;
                max-width: 50%;
                position: absolute;
                bottom: 0;
            }

            &__content {
                width: 100%;
                align-items: flex-start
            }
    
            &__item {     
                width: calc(50% - 10px);

                &--1 {
                    .kiosque {
                        &__item {
                            &__image {
                                @include size(100%,270px);
                            }
    
                            &__content {
                                left: calc(100% + 20px);
                            }
                        }
                    }
                }
    
                &--2 {
                    margin-top: 100px;
                    column-gap: 20px;
                    flex-wrap: wrap;
    
                    .kiosque {
                        &__item {
                            &__image {
                                height: 200px;
                                width: calc(100% - 60px);
                            }
    
                            &__content {
                                max-width: 220px;
                                position: relative;
                                left: 0;
                                bottom: auto;
                            }
    
                            &__buttons {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
    
        }
    }

    .admin-image {
        height: 200px;
        overflow: initial;

        &--2 {
            border-radius: 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        .access{
            margin-left: calc((100% - (90% - $gl-gutter-xs))/2);
            
            .swiper-container {
                height: 430px;
            }
    
            .swiper-slide{  
                width: 170px!important;  

                &:nth-of-type(1),
                &:nth-of-type(7) {
                    height: 205px!important; 
                }
    
                &:nth-of-type(2),
                &:nth-of-type(8) {
                    height: 205px!important; 
                }
    
                &:nth-of-type(3),
                &:nth-of-type(9) {
                    height: 250px!important; 
                }
    
                &:nth-of-type(4),
                &:nth-of-type(10) {
                    height: 160px!important; 
                }
    
                &:nth-of-type(5) {
                    height: 220px!important; 
                }
    
                &:nth-of-type(6) {
                    height: 190px!important; 
                }
            }

            &__content {
                padding: 20px;
            }
        }

        .posts {    
            &__template {
                max-width: 300px;
            }
    
            &__list {    
                &:before {
                    width: calc(100% - 10px);
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
    
            .swiper-slide {
                width: calc(100%)!important;
                height: 250px!important;
            }
        }

        .newsthread {            
            &__container {
                transform: none;
                margin-bottom: -30px;
                padding: 20px;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 10px;
                height: auto;
            }
    
            &__title {
                font-size: toRem(18);
            }
    
            &__link {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 10px;
                padding-right: 0;

                &:before {
                    display: block;
                }

                &:hover, &:focus-within {
                    &:before {
                        width: 30px;
                    }
                }
            }
        }

        .events {    
            > .container {
                padding-bottom: 150px;
            }

            &__template {
                max-width: 300px;
                flex-direction: column;
            }

            &__content {
                row-gap: 30px;
            }
            
            &__list {    
                &:before {
                    width: calc(100% + 20px + ((100vw - ($x-small - $gl-gutter-xs))/2)); // width 100% du container + 20px du padding gauche + l'espace à droite du container-écran
                }
            }
    
            .swiper-slide {
                width: calc(100%)!important;
            }

            &__item {
                &--0 {
                    flex-direction: column;
                    height: auto;

                    .events {
                        &__item {
                            &__container-img {
                                @include size(100%,150px);

                                &:before {
                                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
                                }
                            }

                            &__title {
                                font-size: toRem(18);
                                line-height: toRem(22);
                            }

                            &__taxo {
                                position: absolute;
                                top: 110px;
                                left: 20px;
                                color: $color-white;

                                &:before {
                                    background-color: $color-white;
                                }
                            }

                            &__date {
                                font-size: toRem(18);
                                line-height: toRem(25);
                                margin: 10px 0;
                            }

                            &__infos {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .kiosque {
            padding: 60px 0;

            > .container {
                padding-bottom: 80px;
            }

            &__template {
                max-width: 100%;
            }

            &__content {
                flex-direction: column;
                row-gap: 20px;
            }

            &__item {
                width: 100%;

                &__content {
                    margin-top: -10px;
                }

                &__title {
                    margin: 5px 0 0;
                }

                &__btn {
                    svg {
                        @include size(35px);
                    }
                }

                &--1 {
                    .kiosque {
                        &__item {
                            &__image {
                                @include size(100%,225px);
                            }

                            &__content {
                                position: relative;
                                top: 0;
                                left: 0;
                                max-width: 100%;
                            }
                        }
                    }
                }
                
                &--2 {
                    margin-top: 0;

                    .kiosque {
                        &__item {
                            &__image {
                                // width: calc(100% - 55px);
                                width: 100%;
                                height: 200px;
                            }
                            
                            &__content {
                                position: relative;
                                top: 0;
                                left: 0;
                                max-width: 100%;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .admin-image {
        height: 180px;
    }
}
