.cover {
    position: relative;

    &__image-wrapper {
        position: relative;
        margin-left: calc(50% - 20px);
        width: calc(100% - (50% - 20px));
        height: 550px;
        background-color: $color-bg--image;
        border-radius: $border-radius 0 0 $border-radius;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%);
            background: rgba(128,128,128,.3);
        }

        picture {
            display: block;
        }

        picture, img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        margin-top: 40px;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    &__title {
        color: $color-main;
        margin: 0;
    }

    &__intro {
        color: $color-main;
        font-size: $font-size--text-medium;
        line-height: toRem(30);
        font-weight: $font-weight-semibold;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            @include size(calc(90% - 20px),380px);
            margin: auto;
            border-radius: $border-radius;
        }

        &__informations {
            margin-top: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {        
        &__image-wrapper {
            height: 255px;
        }
    }

}
