.nav-main {

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
    }

    &__home-rwd {
        display: none;
    }

    // Main menu
    .menu {
        @include flex($wrap: wrap);
        flex-direction: column;
        row-gap: 30px;
        width: 335px;

        &__item {
            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    /* opacity: 1; */
                    visibility: visible;
                    pointer-events: inherit;
                transition: .45s ease-in;   
                animation: slide-submenu .75s ease-in-out 0s 1 normal forwards;
        
                @keyframes slide-submenu {
                    from {
                        opacity: 0;
                        transform: translateX(650px);
                    }
                    50% {
                        opacity: 0;
                        transform: translateX(0);
                    }
                
                    to {
                        opacity: 1;
                        transform: translateX(0); 
                    }
                }
                }

                .menu__link {
                    color: $color-main;
                }
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            color: $color-main;
            column-gap: 10px;
            font-weight: $font-weight-bold;
            font-size: toRem(25);
            line-height: toRem(30);
            padding: 0 30px 0 0;
            transition: $duration;
    
            &:before {
                content: '';
                display: inline-block;
                @include size(12px);
                background-color: $color-two;
                border-radius: $border-radius--round;
                transition: $duration;
            }
    
            &:hover, &:focus {
                color: $color-text;
                padding: 0 10px 0 20px;

                &:before {
                    background-color: $color-three;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: flex-start);
        flex-direction: column;
        row-gap: 30px;
        position: absolute;
        right: 0;
        z-index: 3;
        top: 0;
        height: 100%;
        width: 835px;
        padding: 95px 0px 120px 55px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        left: calc((100% - ($large - $gl-gutter))/2 + 335px); //335px = width ul menu
        box-sizing: border-box;
        /* transition: {
            property: visibility, opacity, pointer-events; 
            duration: .15s, 0;
            delay: 500ms, 0ms;
            timing-function: $timing;
        } */

        &__title {
            display: flex;
            align-items: center;
            column-gap: 15px;
            color: $color-main;
            font-size: toRem(25);
            line-height: toRem(30);
            font-weight: $font-weight-bold;
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            width: fit-content;
            text-transform: none;

            &:before {
                content: '';
                display: inline-block;
                @include size(40px);
                background: url(/wp-content/themes/laille/assets/src/images/sprite-images/pictos/ic_nav_gauche_dark.svg) no-repeat center;
                background-color: $color-white-third;
                border-radius: $border-radius--round;
                border: 1px solid $color-white-third;
                transition: $duration;
            }

            &:hover, &:focus-within {
                &:before {
                    border-color: $color-dark-white;
                }
            }
        }

        &__nav {
            width: 100%;
            column-count: 2;
            column-gap: 30px;
            row-gap: 5px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 10px;
            width: 100%;
            padding: 10px 20px 10px 0;
            line-height: toRem(25);
            color: $color-text;
            font-weight: $font-weight-bold;
            transition: $duration;

            &::before {
                content: '\f345';
                font: normal 20px/1 dashicons;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $color-dark;
                opacity: .5;
                background-color: $color-bg--transparent;
                border-radius: $border-radius--round;
                transition: $duration;
                @include size(26px);
                flex: none;
            }

            &:hover, &:focus {
                padding: 10px 5px 10px 15px;
                color: $color-one;

                &::before {
                    background-color: $color-white-third;
                    opacity: 1;
                }
            }

            &--grandchild {
                display: flex;
                padding: 10px 5px 10px 20px;
                border-bottom: 0;

                &::before {
                    display: none;
                }

                &:hover, &:focus-within {
                    padding: 10px 5px 10px 20px;
                }
            }
        }
        
        /* &__close {
            @include absolute($top: 13px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            background-color: $color-bg--transparent;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &__icon {
                display: block;
                @include size(26px);
                margin-right: 7px;
                background-color: $color-main;
                border: $btn-border-width $btn-border-style $btn-border-color;
                border-radius: $border-radius--round;
                fill: $color-white;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-main;
                }
            }
        } */
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }
        
    // Burger menu responsive - Open menu
    &__button {
        position: relative;
        justify-content: center;
        align-items: center;
        min-height: 0;
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border: 0;
        border-radius: $border-radius--round;
        cursor: pointer;
        @include size(35px);

        &:hover, &:focus {
            color: $color-white;
        }
    }

    // Button icon (Burger menu responsive - Open menu)
    &__icon {
        position: relative;
        width: 15px;
        height: 2px;
        background-color: $color-second;

        &:before, 
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-color: $color-second;
        }

        &:before {
            top: 6px;
        }

        &:after {
            top: -6px;
        } 
    }

    &__textBtn {
        &:after {
            content: 'Menu';
            color: $color-main;
            font-weight: $font-weight-bold;
            font-size: toRem(20);
            line-height: toRem(30);
            text-transform: none;
        }
    }

}
.js-open-menu {
    .header--fixed {
        &:before {
            height: 450px;
            border-radius: 0;
        }

        .nav-main {
            &__container {
                top: 80px;
            }

            .menu {
                &__link {
                    animation: slide-left .75s ease-in-out 0s 1 normal forwards;
    
                    @keyframes slide-left {
                        from {
                            opacity: 0;
                            transform: translateX(-650px);
                        }
                    
                        to {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
            }

            .submenu__nav {
                animation: opacitySubmenu .5s ease-in 0s 1 normal forwards;
                opacity: 0;

                @keyframes opacitySubmenu {
                    from {
                        opacity: 0;
                    }
                
                    to {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.header--fixed {
    &:before {
        transition: $duration;
    }
    &:after {
        display: none;
    }

    .nav-main {
        &__button {
            display: flex;
            justify-content: flex-start;
            @include size(150px,40px);
            column-gap: 20px;
            border-radius: 0;
            background-color: $color-bg--transparent;
            z-index: 1;
            position: absolute;
            right: calc((100% - ($large - $gl-gutter))/2 + 230px);
            top: 0;
            bottom: 0;
            margin: auto;
            
            &:before {
                content: '';
                position: absolute;
                left: 0;
                border-radius: $border-radius--round;
                background-color: $color-main;
                @include size(40px);
            }
        }
        &__icon {
            display: block;
            margin-left: 13px;
        }
        &__textBtn {
            display: inline-block;
        }

        &__container {
            position: fixed;
            top: -100%;
            margin: 0 auto;
            max-width: 1200px;
            padding: 0 15px;
            left: 0;
            right: 0;
            @include size(100%,370px);
            transition: left $duration $timing;

            &:before {
                content: "";
                @include size(100vw,1px);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                margin: auto;
                background-color: $color-light-same;
                z-index: 4;
            } 

        }

        .menu {
            &__link {
                opacity: 0;
                transition: .45s ease-in;   
            }
        }

        .submenu {
            height: 100%;
            padding: 0 0 0 50px;
            justify-content: center;
            background-color: $color-light;
            opacity: 0;
            /* transition: .45s ease-in; */   

            &__title {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                background-color: $color-light;
                right: -1000px;
                top: 0;
                transition: $duration;
                width: 1000px;
                height: 100%;
            } 
        }

        &.js-open-menu {
            .nav-main {
                &__icon {
                    background-color: $color-bg--transparent;

                    &:before {
                        top: 0px;
                        transform: rotate(-45deg);
                    }
                    &:after {
                        top: 0px;
                        transform: rotate(45deg);
                    }
                }
                &__textBtn {
                    &:after {
                        content: 'Fermer';
                    }
                }
            }
        }
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            width: 310px;
        }

        .submenu {
            row-gap: 20px;
            width: 630px;
            padding: 70px 0 60px 35px;
            left: calc((100% - ($medium - $gl-gutter-md))/2 + 310px); 

            &__nav {
                column-gap: 10px; 
            }
        }
    }

    .header--fixed {
        .nav-main {
            &__container {
                max-width: 960px;
                padding: 0 10px;
                @include size(100%,420px);    
            }

            &__button {
                right: calc((100% - ($medium - $gl-gutter-md))/2 + 230px);
            }

            .submenu {
                padding: 0 0 0 30px;
            }
        }
    }
    .js-open-menu .header--fixed:before {
        height: 500px;
    }
}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        &__home-rwd {
            position: absolute;
            display: flex;
            margin: 0 auto 0 40px;
            left: 0;

            svg {
                @include size(150px,35px);
                
                path[fill="#110035"]{
                    fill: $color-main;
                }
            }
        }

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 50px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-third;
            padding: 40px 0;
            transition: left $duration $timing;
        }

        // Burger menu responsive - Open menu
        &__textBtn {
            display: none;
        }
        &__button {
            display: flex;
        }
        &__icon {
            display: block;
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            @include size(35px);
            min-height: 0;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: $color-main;
            border: 1px solid $color-main;
            border-radius: $border-radius--round;

            svg {
                margin: 0;
                fill: $color-second;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
                border-color: $color-main;

                svg {
                    fill: $color-main;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
    
                &.menu-item-has-children {

                    /* &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: none;
                    } */

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            color: $color-main;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        color: $color-main;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                color: $color-main;

                &:hover, &:focus {
                    color: $color-main;
                }
            }
        }

        // Sub menu
        .submenu {
            animation: none!important;
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-light;
            transition: {
                property: visibility, pointer-events, right;
                duration: .35s;
                delay:0ms;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                margin: 0 0 0 40px;
                order: 1;
                font-size: $font-size--text-medium;
                line-height: toRem(25);

                &:before {
                    @include size(35px);
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-main;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}
