.proposition-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
    background-color: $color-dark-white;
    border-radius: $border-radius;

    svg {
        height: 70px;
        max-width: 100px;
        margin: 25px 45px;
        fill: $color-white-dark;
    }

    .proposition-content {
        flex: 1;
        display: flex;
        align-items: center;

        &__text {
            max-width: 730px;
            padding: 20px 0;

            h4 {
                margin: 0 0 10px;
                color: $color-second;
            } 

            div {
                display: flex;
                align-items: center;
                column-gap: 5px;
            }

            svg {
                width: 26px;
                height: 26px;
                margin: 0;
            }

            p {
                font-size: $font-size--text-small;
                color: $color-second;
                margin: 0;

                span {
                    font-weight: 700;
                }
            }
        }
    }

    .button {
        margin: 30px 40px;
        background-color: $color-white-dark;
        border-color: $color-white-dark;
        color: $color-dark-white;

        svg {
            width: 26px;
            height: 26px;
            margin: 0 5px 0 0;
            fill: $color-dark-white;
        }

        &:hover, &:focus {
            background-color: $color-one;
            border-color: $color-one;
            color: $color-white;
            svg {
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960 // à revoir en structure html
@media screen and (max-width: $medium) {

    .proposition-wrapper {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px;
        
        .proposition-content {
            display: flex;
            align-items: baseline;
            flex-direction: column;
            width: 100%;
            padding: 0;

            svg {
                display: none;
            }
            
            &__text {
                width: 100%;
                padding: 0;
            }
        }

        .button {
            position: relative;
            left: auto;
            bottom: auto;
            margin: 20px 0 0 0;
        }
    }

}
